var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dataLoading
        ? [
            _vm._t("tableDataLoading", [
              _c(
                "v-sheet",
                {
                  attrs: {
                    color:
                      "grey " +
                      (_vm.$vuetify.theme.dark ? "darken-2" : "lighten-4")
                  }
                },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      tile: "",
                      type: "table-thead, table-tbody, table-tfoot"
                    }
                  })
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _vm.filters && _vm.filters.length > 0
        ? [
            _c("AESearchFilter", {
              attrs: {
                "filter-dialog": _vm.filterDialog,
                filters: _vm.filters
              },
              on: {
                "filter-data": function($event) {
                  return _vm.execute($event)
                },
                "filter-dialog": function($event) {
                  return _vm.$emit("filter-dialog", $event)
                }
              }
            })
          ]
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-3 pa-5",
        attrs: {
          headers: _vm.availableHeaders,
          "hide-default-header": _vm.dataLoading,
          items: _vm.resultItems,
          loading: _vm.dataLoading,
          options: _vm.options,
          "server-items-length": _vm.totalItems,
          "fixed-header": "",
          "multi-sort": "",
          "headers-length": _vm.availableHeaders.length - 1
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.action",
              fn: function() {
                return undefined
              },
              proxy: true
            },
            _vm.rowActionsFiltered
              ? {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return _vm._l(_vm.rowActionsFiltered, function(action) {
                      return _c(
                        "v-tooltip",
                        {
                          key: action.name,
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    "link" === action.type
                                      ? [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.executeOnRow(
                                                  action.name,
                                                  item
                                                ),
                                                exact: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  { staticClass: "mr-2" },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(action.icon) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : "slink" === action.type
                                      ? [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: action.link,
                                                exact: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  { staticClass: "mr-2" },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(action.icon) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mr-2",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.executeOnRow(
                                                      action.name,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " + _vm._s(action.icon) + " "
                                              )
                                            ]
                                          )
                                        ]
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(action.tooltip))])]
                      )
                    })
                  }
                }
              : null,
            {
              key: "no-results",
              fn: function() {
                return [
                  _vm.dataLoading
                    ? _c("span", { staticClass: "text-caption" }, [
                        _vm._v("Loading data, please wait!")
                      ])
                    : _c("span", { staticClass: "text-caption" }, [
                        _vm._v("No results found")
                      ])
                ]
              },
              proxy: true
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _vm.dataLoading
                    ? _c("span", { staticClass: "text-caption" }, [
                        _vm._v("Loading data, please wait!")
                      ])
                    : _c("span", { staticClass: "text-caption" }, [
                        _vm._v("No data found")
                      ])
                ]
              },
              proxy: true
            },
            {
              key: "loading",
              fn: function() {
                return [
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v("Loading data, please wait!")
                  ])
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }